const initialState = {
  charas: {
    list: [],
  },
  users: {
    isSignedIn: false,
    role: "",
    uid: "",
    username: "",
  },
};

export default initialState;
